import { faGithubSquare, faLinkedin, faMedium } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { ImageLink, Section } from '../component'

export const Contact = () => (
  <Section title="Contact">
    <Box
      sx={{ display: 'flex' }}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Stack direction="row" spacing={2}>
        <ImageLink icon={faEnvelope} link="mailto:hello@abroadbent.com" />
        <ImageLink icon={faMedium} link="https://abroadbent.medium.com" />
        <ImageLink icon={faGithubSquare} link="https://github.com/alexbroadbent" />
        <ImageLink icon={faLinkedin} link="https://linkedin.com/in/alexander-broadbent" />
      </Stack>
    </Box>
  </Section>
)
