import Timeline from '@mui/lab/Timeline'
import Typography from '@mui/material/Typography'

import { Section, TextLink } from '../../component'
import { JourneyItemContract } from './JourneyItemContract'
import { JourneyItemEducation } from './JourneyItemEducation'
import { JourneyItemPerm } from './JourneyItemPerm'
import { JourneyItemProject } from './JourneyItemProject'
import { JourneyLegend } from './JourneyLegend'

export const Journey = () => (
  <Section title="Journey">
    <JourneyLegend />
    <Timeline>
      <JourneyItemProject time="Dec 2023" title="Speaker Slot at a Tech Conference">
        <Typography variant="body2" paragraph>
          I was honoured to be invited to speak at the apidays conference in CNIT, Paris.
        </Typography>
        <Typography variant="body2" paragraph>
          I created a live demo to share alongside my presentation on "Making the Most of Your
          OpenAPI Spec". During this presentation I also provided insights from how I built up the
          API documentation and tooling in my team at SAPI.
        </Typography>
      </JourneyItemProject>
      <JourneyItemPerm
        company="SAPI"
        time="Sep 2022 to Now"
        location="London"
        title="Principal Software Engineer"
        responsibilities={[
          'Having joined as #1 engineer, I currently tech lead the Growth & Engagements team',
          'Key deliverables include integrating auth0 and BoldSign, architecting a dashboard for partners to manage their merchants, implementing the pricing framework and repayments framework for servicing loans',
          'Independently developed a contracting framework for white-label partners, including a public API',
          'Spoke at apidays conference in Paris on “Making the Most of your OpenAPI Spec” which included a live demo and going through how I built the API documentation and tooling in my team'
        ]}
        techStack={[
          'TypeScript',
          'NodeJS',
          'React',
          'NextJS',
          'Fastify',
          'PostgreSQL',
          'REST APIs',
          'AWS',
          'Copilot',
          'Playwright',
          'Jest',
          'GitHub Actions',
          'Linear',
          'NPM',
          'Git'
        ]}
      />
      <JourneyItemContract
        client="Handmade in Harpenden"
        time="Aug 2021"
        title="Dashboard"
        techStack={[
          'NodeJS',
          'TypeScript',
          'React',
          'JavaScript',
          'Google Cloud Platform',
          'Google Firebase',
          'Google Firestore',
          'Google Cloud Functions',
          'Stripe API',
          'Square API',
          'Starling API',
          'Xero API',
          'Jest',
          'Yarn',
          'Git'
        ]}
        sx={{ mb: 5 }}
      >
        <Typography variant="body2" paragraph>
          A portal for combining all sources of income into a single dashboard. As a small business,
          HIH did not want to spend their time having to go through each source of income and adding
          them up every day to work out if they were on target for the month.
        </Typography>
        <Typography variant="body2" paragraph>
          By building a single portal, they have a one-stop place to see if they are on target for
          the month and a way to check their targets against previous months and years.
        </Typography>
      </JourneyItemContract>
      <JourneyItemPerm
        company="Tesco"
        time="Apr 2021"
        location="Welwyn Garden City, Hertfordshire"
        title="Senior Software Engineer"
        responsibilities={[
          'As a member of the Quote HTTP API team, I contributed to the real-time pricing pipeline',
          'Key deliverables include leading the launch of the Delivery Saver subscription in Republic of Ireland which involved working across multiple stakeholders',
          'My role also entailed hiring, onboarding and deciding work allocation'
        ]}
        techStack={[
          'Java',
          'Micronaut',
          'Apache Kafka',
          'RxJava',
          'Vertx',
          'Microsoft Azure',
          'AWS',
          'Groovy',
          'Spock',
          'Cucumber',
          'Couchbase',
          'Jenkins',
          'Gradle',
          'Git',
          'Jira'
        ]}
      />
      <JourneyItemPerm
        company="Freetrade"
        time="May 2020"
        location="London"
        title="Full-Stack Software Engineer"
        responsibilities={[
          'This was a full-stack role across a TypeScript server with native Android and iOS apps',
          'Key deliverables include features like Freeshares, SIPPs, OpenBanking Deposits and paid subscriptions within the Growth Team'
        ]}
        techStack={[
          'TypeScript',
          'NodeJS',
          'Android',
          'Kotlin',
          'RxKotlin',
          'iOS',
          'Swift',
          'RxSwift',
          'Google Cloud Platform',
          'Serverless functions',
          'Circle CI',
          'Terraform',
          'Git',
          'Yarn',
          'Jira Next-Gen'
        ]}
      />
      <JourneyItemProject
        time="Apr 2020"
        title="Published first blog post"
        link="https://abroadbent.medium.com"
        linkText="View all articles on Medium"
      >
        <Typography variant="body2" paragraph>
          As a frequent reader of blog posts, it made sense to write my own blog posts in order to
          give back to the community and share my experiences in the hopes of helping other
          engineers.
        </Typography>
        <Typography variant="body2" paragraph>
          My posts have been published from my previous workplaces and personal projects, and have
          been published in Medium publications such as{' '}
          <TextLink href="https://medium.com/interviewnoodle/levels-of-a-software-engineer-interview-c4abc78c4d">
            Interview Noodle
          </TextLink>{' '}
          and{' '}
          <TextLink href="https://blog.devgenius.io/writing-a-dsl-in-kotlin-42a9029b93a6">
            Dev Genius
          </TextLink>
          .
        </Typography>
      </JourneyItemProject>
      <JourneyItemPerm
        company="Flux"
        time="Dec 2018"
        location="London"
        title="Backend Software Engineer"
        responsibilities={[
          'Having joined as #3 engineer, my priority was ensuring we scale with demand, maintain uptime and an accurate receipt match rate',
          'Key deliverables included integrating partners such as Monzo Bank, Starling Bank and Barclays Bank'
        ]}
        techStack={[
          'Kotlin',
          'Dropwizard',
          'Ktor',
          'PostgreSQL',
          'Guice',
          'Kodein',
          'Github deployments',
          'Jira',
          'KoTest',
          'MockK',
          'Kubernetes',
          'Postman',
          'Amazon SQS',
          'Amazon S3',
          'Drone CI',
          'Datadog',
          'OpsGenie',
          'Agile Methodology',
          'Git',
          'Gradle'
        ]}
      />
      <JourneyItemPerm
        company="Dexda"
        time="Jul 2016"
        location="London"
        title="Backend Software Engineer"
        responsibilities={[
          'Having joined as #1 engineer, I helped build the core API, which facilitated real-time processing of log analytics using machine learning',
          'Key deliverables included designing, implementing and maintaining the core REST APIs'
        ]}
        techStack={[
          'Java',
          'MapR',
          'Apache HBase',
          'Apache Kafka',
          'ElasticSearch',
          'Dropwizard',
          'JUnit',
          'AssertJ',
          'Mockito',
          'AWS',
          'Postman',
          'Prometheus',
          'Kubernetes',
          'Travis CI',
          'Gitlab',
          'Agile',
          'Git',
          'Maven',
          'Jira'
        ]}
      />
      <JourneyItemEducation
        time="Jul 2016"
        title="Finished Studies"
        school="Queen Mary, University of London"
        contentSx={{ mb: 2 }}
      >
        <Typography variant="body2" paragraph>
          BSc Computer Science with Industrial Placement
        </Typography>
        <Typography variant="body2" paragraph>
          Graduated with 2:1 (with honours)
        </Typography>
      </JourneyItemEducation>
      <JourneyItemPerm
        company="ServiceNow"
        time="Aug 2014-15"
        location="Egham, Surrey"
        title="Placement Year: Application Developer"
        responsibilities={[
          'Developed core skills in software development practices at a multi-national enterprise company',
          'Maintained and developed features on the Service Catalog application'
        ]}
        techStack={[
          'Java',
          'JavaScript',
          'HTML',
          'CSS',
          'XML',
          'JUnit',
          'MySQL',
          'ITIL',
          'Selenium',
          'Jenkins'
        ]}
      />
    </Timeline>
  </Section>
)
