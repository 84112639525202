import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Unstable_Grid2'
import { useView } from '../hook'

export const Intro = () => (
  <Grid container direction="column" justifyContent="center" alignItems="center">
    <Avatar alt="Alexander Broadbent" src="/img/me.jpeg" sx={{ width: 128, height: 128 }} />
    {useView(
      <Typography variant="h1" sx={{ fontSize: '3rem', fontWeight: 'bold', py: '1vh' }}>
        Alexander Broadbent
      </Typography>,
      <>
        <Typography variant="h1" sx={{ fontSize: '3rem', fontWeight: 'bold', pt: '1vh' }}>
          Alexander
        </Typography>
        <Typography variant="h1" sx={{ fontSize: '3rem', fontWeight: 'bold', pb: '1vh' }}>
          Broadbent
        </Typography>
      </>
    )}
    <Typography
      variant="h3"
      sx={{ fontSize: '1.5rem', fontWeight: 'bold', py: '1vh', color: 'text.secondary' }}
    >
      Principal Software Engineer
    </Typography>
  </Grid>
)
