import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import { Section } from '../../component'
import { BlogItem } from './BlogItem'

export const Blog = () => (
  <Section title="Blog">
    <Grid container spacing={2}>
      <BlogItem
        title="Hosting your website for free"
        created="Aug 8, 2023"
        link="https://abroadbent.medium.com/hosting-your-website-for-free-e2d9c0783aae"
      >
        I’ll show you how I host my website abroadbent.com for free using AWS S3 and Cloudflare. In
        order to start hosting your static website…
      </BlogItem>
      <BlogItem
        title="My Day in the Life at SAPI"
        created="Jul 13, 2023"
        link="https://abroadbent.medium.com/my-day-in-the-life-at-sapi-904728f4dc4a"
      >
        I was recently interviewed at SAPI for their “Day in the Life” series. I hope you enjoy
        getting an insight into a typical day with me…
      </BlogItem>
      <BlogItem
        title="My 8 Secrets to Scaling Tech Successfully"
        created="Mar 9, 2023"
        link="https://medium.com/sapigroup/my-8-secrets-to-scaling-tech-successfully-c025804e5b18"
      >
        Reflecting on my experience working at multiple high growth startups, here are my top 8
        areas which I believe are most important when…
      </BlogItem>
      <BlogItem
        title="How We Embrace Being a Design-Driven API at SAPI"
        created="Nov 23, 2022"
        link="https://medium.com/sapigroup/how-we-embrace-being-a-design-driven-api-at-sapi-f268581a544f"
      >
        We have all been there when you look at the documentation and write a client that fetches
        some data, only to then find that the response…
      </BlogItem>
      <BlogItem
        title="Building a Custom Finance Dashboard Using Multiple Payment Provider APIs"
        created="Oct 16, 2022"
        link="https://abroadbent.medium.com/building-a-custom-finance-dashboard-using-multiple-payment-provider-apis-1fbb25673420"
      >
        The client, Handmade in Harpenden, have multiple streams of revenue, varying from B2C and
        B2B, which made it difficult for the team to…
      </BlogItem>
      <BlogItem
        title="Learnings from Devoxx 2022"
        created="Aug 19, 2022"
        link="https://abroadbent.medium.com/learnings-from-devoxx-2022-27fe6d6b4d25"
      >
        Here are my highlights from this year&apos;s conference if you missed it. It&apos;s clear
        there has been a lot of excitement around the future of Java…
      </BlogItem>
      <BlogItem
        title="Writing an IntelliJ Plugin for Inserting Timestamps"
        created="Jul 24, 2022"
        link="https://abroadbent.medium.com/writing-an-intellij-plugin-for-inserting-timestamps-2cbe6f09b4f8"
      >
        Whilst writing a lot of tests involving time-based functions, I got tired of having to
        manually write out full timestamps, I was copying…
      </BlogItem>
      <BlogItem
        title="Easy JSON in Kotlin with a Type-Safe Builder DSL"
        created="Jan 8, 2022"
        link="https://abroadbent.medium.com/writing-a-dsl-in-kotlin-42a9029b93a6"
      >
        After learning of the type-safe builders in Kotlin in 2019, I wanted to create a
        domain-specific language (DSL) to avoid having to use the…
      </BlogItem>
    </Grid>

    <Box textAlign="center" sx={{ mt: 5 }}>
      <Button href="https://blog.abroadbent.com">View all Blog Posts</Button>
    </Box>
  </Section>
)
