import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import { TestimonialItem } from './TestimonialItem'
import { Section } from '../../component'

export const Testimonials = () => (
  <Section title="Testimonials">
    <Grid container spacing={2}>
      <TestimonialItem client="SAPI" name="Sam Leonard-Williams" position="Head of Product">
        <Typography variant="body1" paragraph>
          Alex is an exceptionally talented engineer! His technical prowess, problem-solving skills,
          and deep understanding of software development have consistently impressed me. Whether
          it's architecting complex systems, tackling challenging problems, or optimising code for
          performance, he approaches every task with unmatched enthusiasm and expertise.
        </Typography>
        <Typography variant="body1" paragraph>
          Beyond his technical abilities, Alex is an absolute pleasure to work with, being equally
          at home as both a leader, team member and individual contributor. He brings a positive
          attitude and a sense of humour to the workplace that lightens the atmosphere and makes
          even the most challenging projects enjoyable.
        </Typography>
        <Typography variant="body1" paragraph>
          As a Product Manager, working with engineers of Alex's calibre make my job a whole lot
          easier - I really hope we can work together again in the future!
        </Typography>
      </TestimonialItem>
      <TestimonialItem client="Handmade in Harpenden" name="Farhana Haque" position="Founder">
        <Typography variant="body1" paragraph>
          Alex has been great to work with in building a fully bespoke sales and target tracking
          dashboard. He is extremely patient and understanding, with an exceptional ability to
          convert technical knowledge into business speak. He kept us in the loop with progress
          during the project and provided regular updates for feedback.
        </Typography>
        <Typography variant="body1" paragraph>
          The whole team now use the dashboard he built us as our single source of truth, which
          helps us monitor team targets and decide strategy in real time.
        </Typography>
      </TestimonialItem>
      <TestimonialItem client="SAPI" name="Pavlos Georgiou" position="CTO">
        <Typography variant="body1" paragraph>
          I had the pleasure of working closely with Alex.
        </Typography>
        <Typography variant="body1" paragraph>
          Alex isn't just a technically adept engineer; he's also an incredibly friendly and
          adaptable teammate. His relaxed and approachable demeanor facilitates seamless
          collaboration.
        </Typography>
        <Typography variant="body1" paragraph>
          What impressed me most about Alex was his ability to inject fresh ideas into our projects.
          He consistently raised interesting points, challenged assumptions, and paid meticulous
          attention to tricky details. His contributions elevated the quality of our work.
        </Typography>
        <Typography variant="body1" paragraph>
          I wholeheartedly recommend Alex for any engineering team. His technical skills,
          versatility, and creative thinking are invaluable assets. It was a true pleasure working
          together.
        </Typography>
      </TestimonialItem>
    </Grid>
  </Section>
)
